import reduce from "image-blob-reduce";

const reducer = reduce();
reducer._calculate_size = function (env) {
  let ratio = Math.min(
    env.opts.maxWidth / env.image.width,
    env.opts.maxHeight / env.image.height
  );

  if (ratio > 1) ratio = 1;
  else if (ratio > -0.00001 && ratio < 0.000001) {
    ratio = 1;
  }

  env.transform_width = Math.round(env.image.width * ratio);
  env.transform_height = Math.round(env.image.height * ratio);

  return env;
};

export default {
  loadImage(url) {
    /*
     * We are going to return a Promise which, when we .then
     * will give us an Image that should be fully loaded
     */
    return new Promise((resolve) => {
      /*
       * Create the image that we are going to use to
       * to hold the resource
       */
      const image = new Image();
      /*
       * The Image API deals in even listeners and callbacks
       * we attach a listener for the "load" event which fires
       * when the Image has finished the network request and
       * populated the Image with data
       */
      image.addEventListener("load", () => {
        /*
         * You have to manually tell the Promise that you are
         * done dealing with asynchronous stuff and you are ready
         * for it to give anything that attached a callback
         * through .then a realized value.  We do that by calling
         * resolve and passing it the realized value
         */
        resolve(image);
      });
      /*
       * Setting the Image.src is what starts the networking process
       * to populate an image.  After you set it, the browser fires
       * a request to get the resource.  We attached a load listener
       * which will be called once the request finishes and we have
       * image data
       */
      image.src = url;
    });
  },
  async resize(file, maxWidth, maxHeight) {
    let image = await reducer.toBlob(file, {
      maxWidth: maxWidth,
      maxHeight: maxHeight,
    });
    let imageSrc = await this.loadImage(URL.createObjectURL(image));
    const finalCanvas = document.createElement("canvas");
    finalCanvas.width = maxWidth;
    finalCanvas.height = maxHeight;
    const ctx = finalCanvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, maxWidth, maxHeight);
    ctx.drawImage(
      imageSrc,
      finalCanvas.width / 2 - imageSrc.width / 2,
      finalCanvas.height / 2 - imageSrc.height / 2
    );

    const toBlob = (type, quality) =>
      new Promise((resolve) => finalCanvas.toBlob(resolve, type, quality));
    let endBlob = await toBlob("image/jpeg", 1);
    return endBlob;
  },
};

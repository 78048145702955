import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getWhitePapers(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/WhitePapers/GetWhitePapers",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getContentRemaining(companyid) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/WhitePapers/ContentRemaining/${companyid}`,
      authHeaders
    );
    return res.data;
  },
  async getWhitepapersForSite(companyid, siteid) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/WhitePapers/WhitepapersBySite/${companyid}/${siteid}`,
      authHeaders
    );
    return res.data;
  },
  async saveContent(whitepaper) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      `api/WhitePapers/NewSaveWhitepaper`,
      whitepaper,
      authHeaders
    );
    return res.data;
  },
  async archiveWhitepaperSite(companyid, siteid, whitepaper) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      `api/WhitePapers/ArchiveWhitepaper/${companyid}/${siteid}`,
      whitepaper,
      authHeaders
    );
    return res.data;
  },
  async getWhitePaper(companyid, whitepaperid) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/WhitePapers/WhitepaperById/${companyid}/${whitepaperid}`,
      authHeaders
    );
    return res.data;
  },
  async getWhitePaperSites(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/WhitePapers/GetWhitePaperSites",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getWhitePaperByTitle(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/WhitePapers/GetWhitePaperByTitle",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getWhitePaperSiteCategory(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/WhitePapers/GetWhitePaperSiteCategory",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getSiteCategoryForWhitePaper(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/WhitePapers/GetSiteCategoryForWhitePaper",
      filter,
      authHeaders
    );
    return res.data;
  },
  async clientUploadUrl(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/WhitePapers/ClientUpload",
      upload,
      authHeaders
    );
    return res.data;
  },
  async uploadWhitePaper(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/WhitePapers/Upload", upload, authHeaders);
    return res.data;
  },
  async addWhitePaper(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/WhitePapers/Add", order, authHeaders);
    return res.data;
  },
  async deleteWhitePaper(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/WhitePapers/Delete", order, authHeaders);
    return res.data;
  },
  async saveWhitePaper(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/WhitePapers/Save", order, authHeaders);
    return res.data;
  },
};

import APIService from "./APIService";

const http = APIService.http;

export default {
  async commit(fileUrl, blocks, contentType) {
    var uri = fileUrl + "&comp=blocklist";

    let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (let i = 0; i < blocks.length; i++) {
      requestBody += "<Latest>" + blocks[i] + "</Latest>";
    }
    requestBody += "</BlockList>";

    return await http.put(uri, requestBody, {
      headers: {
        "x-ms-blob-content-type": contentType,
        "Content-Type": "application/xml",
      },
    });
  },
  async upload(config) {
    let blocks = [];
    let currentPointer = 0;
    let block = 1;
    let blockSize = 1024 * 256;
    let bytesRemaining = config.file.size;
    const blockPrefix = "block-";

    if (config.file.size < blockSize) {
      blockSize = config.file.size;
    }

    while (bytesRemaining > 0) {
      let currentBytes = new Uint8Array(
        await config.file
          .slice(currentPointer, currentPointer + blockSize)
          .arrayBuffer()
      );
      let currentBlockId = `${blockPrefix}${this.pad(block, 6)}`;
      blocks.push(btoa(currentBlockId));

      await http.put(
        `${config.baseUrl}${config.sasToken}&comp=block&blockid=${btoa(
          currentBlockId
        )}`,
        currentBytes,
        {
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": config.file.type,
          },
        }
      );

      block += 1;
      currentPointer += blockSize;
      bytesRemaining -= blockSize;
      if (bytesRemaining < blockSize) {
        blockSize = bytesRemaining;
      }
    }

    await this.commit(
      `${config.baseUrl}${config.sasToken}`,
      blocks,
      config.file.type
    );
  },
  pad(number, length) {
    let str = "" + number;
    while (str.length < length) {
      str = "0" + str;
    }
    return str;
  },
};

<template>
  <div
    class="card p-4 d-flex align-content-center justify-content-center align-items-center"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div class="progress progress-sm card-progress" v-if="uploading">
      <div class="progress-bar progress-bar-indeterminate"></div>
    </div>

    <input
      type="file"
      :id="`assetsFieldHandle-${uniqueId}`"
      class="w-0 h-0 opacity-0 overflow-hidden position-absolute"
      @change="onChange"
      ref="file"
      :accept="accept"
    />

    <label
      :for="`assetsFieldHandle-${uniqueId}`"
      class="d-block cursor-pointer"
      v-if="!file"
    >
      <div class="text-center">
        <strong>
          Drag &amp; drop {{ label }} here or
          <span class="text-decoration-underline">click here</span> to select a
          {{ label }}
        </strong>
      </div>

      <div class="text-center" v-if="helpText">
        <span class="text-muted">{{ helpText }}</span>
      </div>
    </label>
    <div class="d-flex flex-column align-items-center" v-if="file">
      <p class="pb-0 mb-2" v-if="uploading">
        Uploading <strong>{{ file.name }}</strong
        >, please wait.
      </p>
      <div class="mb-2 pb-0" v-else>
        <div class="text-center" v-if="inlinePreview">
          <img
            draggable="false"
            class="preview d-block mb-1 mx-auto"
            v-if="displayWidth && displayHeight"
            :width="displayWidth"
            :height="displayHeight"
            :src="value.baseUrl"
          />
          <img
            draggable="false"
            class="preview d-block mb-1 mx-auto"
            :src="value.baseUrl"
            v-else
          />
          <a href="#" v-if="!uploading" @click.prevent="remove">
            Change {{ label }}
          </a>
        </div>
        <div v-else>
          <p class="p-0 m-0 mb-2">
            Uploaded <strong>{{ file.name }}</strong
            >.&nbsp;
            <a href="#" v-if="!uploading" @click.prevent="remove">Change</a>
          </p>

          <p class="text-center p-0 m-0">
            <a href="#" target="_blank" @click.prevent="preview">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-external-link"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                ></path>
                <line x1="10" y1="14" x2="20" y2="4"></line>
                <polyline points="15 4 20 4 20 9"></polyline>
              </svg>
              Open Preview
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="text-center" v-if="error">
      <span class="text-red">
        <strong>File must be a {{ accept }}.</strong>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.preview {
  max-width: 200px;
  max-height: 200px;
}
</style>

<script>
import WhitePaperService from "../../../services/WhitePaperService";
import ImageResizeService from "../../../services/ImageResizeService";
import AzureBlobUploadService from "../../../services/AzureBlobUploadService";

export default {
  name: "content.upload.drag-and-drop-upload-card",
  props: [
    "value",
    "contentTypes",
    "label",
    "inlinePreview",
    "uniqueId",
    "uploadTo",
    "resizeImage",
    "maxWidth",
    "maxHeight",
    "displayWidth",
    "displayHeight",
    "helpText",
  ],
  data() {
    return {
      file: null, // Store our uploaded files
      uploadUrl: null,
      uploading: false,
      error: false,
    };
  },
  watch: {
    value() {
      if (!this.uploadUrl && this.value) {
        this.file = {
          name: this.value.clientName,
        };
        this.uploadUrl = {
          sasToken: this.value.sasToken,
          baseUrl: this.value.baseUrl,
          serverName: this.value.serverName,
        };
      } else if (!this.value) {
        this.remove();
      }
    },
  },
  mounted() {
    if (this.value) {
      this.file = {
        name: this.value.clientName,
      };
      this.uploadUrl = {
        sasToken: this.value.sasToken,
        baseUrl: this.value.baseUrl,
        serverName: this.value.serverName,
      };
    }
  },
  computed: {
    accept() {
      return this.contentTypes.map((c) => `.${c}`).join(", ");
    },
  },
  methods: {
    preview() {
      window.open(
        this.value.baseUrl,
        "popUpWindow",
        "height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes"
      );
    },
    async onChange() {
      if (!this.uploading) {
        this.uploading = true;
        let file = this.$refs.file.files[0];
        const extension = file.name.split(".").pop();
        if (!this.accept.includes(extension)) {
          this.error = true;
          this.uploading = false;
          return;
        }
        this.file = file;
        let name = this.file.name;
        if (this.resizeImage) {
          this.file = await ImageResizeService.resize(
            file,
            this.maxWidth,
            this.maxHeight
          );
          name = "image.jpg";
        }
        this.uploadUrl = await WhitePaperService.clientUploadUrl({
          filename: name,
          contentType: this.file.type,
          fileSize: this.file.size,
          uploadTo: this.uploadTo,
        });
        await this.upload();
      }
    },
    async upload() {
      console.log(this.file, this.uploadUrl);
      await AzureBlobUploadService.upload({
        file: this.file,
        baseUrl: this.uploadUrl.baseUrl,
        sasToken: this.uploadUrl.sasToken,
      });
      this.$emit("input", {
        clientName: this.file.name,
        baseUrl: this.uploadUrl.baseUrl,
        sasToken: this.uploadUrl.sasToken,
        serverName: this.uploadUrl.serverName,
      });
      this.uploading = false;
    },
    remove() {
      this.file = null;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (
        !event.currentTarget.classList.contains("border-success") &&
        !this.uploading
      ) {
        event.currentTarget.classList.add("border-success");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("border-success");
    },
    drop(event) {
      event.preventDefault();
      this.error = false;
      if (
        !this.uploading &&
        event.dataTransfer.files &&
        event.dataTransfer.files.length > 0
      ) {
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
      }
      // Clean up
      event.currentTarget.classList.remove("border-success");
    },
  },
};
</script>
